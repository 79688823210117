<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="4"
          :style="selectedCustomSearch ? 'display: none;' : '' "
          class="mb-1"
        >
          <b-row>
            <b-col cols="6">
              <small style="font-size: 10px;">Select Desired Status</small>
              <div style="margin-top: 10px;">
                <b-form-radio
                  v-model="SelectedStatus"
                  name="some-radioStatus"
                  value="Ocurred"
                  class="custom-control-primary"
                  :disabled="selectedCustomSearch"
                >
                  Ocurred
                </b-form-radio>
                <b-form-radio
                  v-model="SelectedStatus"
                  name="some-radioStatus"
                  value="Created"
                  class="custom-control-primary"
                  :disabled="selectedCustomSearch"
                >
                  Created
                </b-form-radio>
                <b-form-radio
                  v-model="SelectedStatus"
                  name="some-radioStatus"
                  value="Open"
                  class="custom-control-primary"
                  :disabled="selectedCustomSearch"
                >
                  Open
                </b-form-radio>
              </div>
            </b-col>
            <b-col cols="6">
              <small style="font-size: 10px;">Select Temporally</small>
              <div style="margin-top: 10px;">
                <b-form-radio
                  v-model="SelectedTemporally"
                  name="some-radioTemporally"
                  value="Today"
                  class="custom-control-primary"
                  :disabled="selectedCustomSearch"
                >
                  Today
                </b-form-radio>
                <b-form-radio
                  v-model="SelectedTemporally"
                  name="some-radioTemporally"
                  value="Yesterday"
                  class="custom-control-primary"
                  :disabled="selectedCustomSearch"
                >
                  Yesterday
                </b-form-radio>
                <b-form-radio
                  v-model="SelectedTemporally"
                  name="some-radioTemporally"
                  value="This Week"
                  class="custom-control-primary"
                  :disabled="selectedCustomSearch"
                >
                  This Week
                </b-form-radio>
                <b-form-radio
                  v-model="SelectedTemporally"
                  name="some-radioTemporally"
                  value="This Month"
                  class="custom-control-primary"
                  :disabled="selectedCustomSearch"
                >
                  This Month
                </b-form-radio>
                <b-form-radio
                  v-model="SelectedTemporally"
                  name="some-radioTemporally"
                  value="This Year"
                  class="custom-control-primary"
                  :disabled="selectedCustomSearch"
                >
                  This Year
                </b-form-radio>
              </div>
            </b-col>
          </b-row>
          <b-row style="margin-top: 15px;">
            <b-col cols="12">
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                :config="{ mode: 'range'}"
                :style="selectedCustomSearch ? 'opacity: 0.4 !important; background-color: #F1F1F1;' : '' "
                :disabled="selectedCustomSearch"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          :md="!selectedCustomSearch ? '4' : '3'"
          class="mb-1"
        >
          <b-row>
            <b-col cols="12">
              <b-form-checkbox
                v-model="selectedCustomSearch"
              >
                Custom Search
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row style="margin-top: 40px;">
            <b-col cols="12">
              <small style="font-size: 10px;">Appointments Date</small>
              <flat-pickr
                v-model="rangeDate1"
                class="form-control"
                :config="{ mode: 'range'}"
                :disabled="!selectedCustomSearch"
                :style="!selectedCustomSearch ? 'opacity: 0.4 !important; background-color: #F1F1F1;' : '' "
              />
            </b-col>
          </b-row>
          <b-row style="margin-top: 7px;">
            <b-col cols="12">
              <small style="font-size: 10px;">Creation Date</small>
              <flat-pickr
                v-model="rangeDate2"
                class="form-control"
                :config="{ mode: 'range'}"
                :disabled="!selectedCustomSearch"
                :style="!selectedCustomSearch ? 'opacity: 0.4 !important; background-color: #F1F1F1;' : '' "
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          :md="!selectedCustomSearch ? '4' : '9'"
          class="mb-1"
        >
          <div class="mb-1">
            <h4 class="mb-0">
              0 Appointments
            </h4>
          </div>
          <div class="card-text">
            Appt. Date: October 01, 2021 - October 31, 2021
          </div>
          <div class="card-text">
            Created Date: October 01, 2021 - October 31, 2021
          </div>
          <div
            class="d-flex flex-wrap"
            style="margin-top: 8px;"
          >
            <b-button
              variant="warning"
              class="mr-1 mb-1"
              size="sm"
            >
              <feather-icon
                icon="HexagonIcon"
                class="mr-50"
              />
              <span class="align-middle">Erase Filters</span>
            </b-button>
            <b-button
              variant="warning"
              class="mr-1 mb-1"
              size="sm"
            >
              <feather-icon
                icon="EyeIcon"
                class="mr-50"
              />
              <span class="align-middle">View Details</span>
            </b-button>
            <b-button
              variant="warning"
              class="mr-1 mb-1"
              size="sm"
            >
              <feather-icon
                icon="SendIcon"
                class="mr-50"
              />
              <span class="align-middle">See Appointments</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card
          style="box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5); overflow: hidden; padding: 20px; backdrop-filter: blur(10px); padding: 0px; height: 80px;"
          no-body
          class="backgroundGlass"
        >
          <b-card
            style="box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5); overflow: hidden; padding: 20px; backdrop-filter: blur(10px); background-color: transparent; margin-bottom: 0px;"
            no-body
          >
            <vue-perfect-scrollbar
              class="anotherscrollvueheight"
            >
              <b-badge
                v-for="(data, indexl) in listData"
                :key="indexl"
                variant="light-primary"
                style="margin-right: 10px; margin-bottom: 10px;"
              >
                Primary
              </b-badge>
            </vue-perfect-scrollbar>
          </b-card>
        </b-card>
      </b-col>
    </b-row>
    <b-card
      style="padding-left: 0px !important; padding-right: 0px !important;"
    >
      <b-row>
        <b-col
          v-for="(item, index) in items"
          :id="`MyList${index}`"
          :key="index"
          md="6"
          lg="3"
        >
          <b-card
            style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important; padding-left: 0px; padding-right: 0px;"
            no-body
          >
            <b-card-body style="padding-bottom: 0px;">
              <b-card-title style="font-size: 15px">
                {{ item }}
              </b-card-title>
            </b-card-body>
            <b-list-group>
              <vue-perfect-scrollbar>
                <b-list-group-item
                  v-for="(data, indexl) in listData"
                  :key="indexl"
                  class="d-flex justify-content-between align-items-center"
                  flush
                >
                  <small>{{ data.text }}</small>
                  <div>
                    <small>100</small>
                    <b-badge
                      variant="primary"
                      class="badge-round ml-1"
                    >
                      <div style="font-size: 10px">
                        8%
                      </div>
                    </b-badge>
                  </div>
                </b-list-group-item>
              </vue-perfect-scrollbar>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      no-body
      class="card-revenue-budget"
    >
      <b-row class="mx-0">
        <b-col
          class="revenue-report-wrapper"
        >
          <div class="d-sm-flex justify-content-between align-items-center mb-3">
            <h4 class="card-title mb-50 mb-sm-0">
              Appointments By Creation Date
            </h4>
          </div>

          <!-- chart -->
          <vue-apex-charts
            id="revenue-report-chart"
            type="bar"
            height="230"
            :options="revenueReport.chartOptions"
            :series="series"
          />
        </b-col>
      </b-row>
    </b-card>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BCardTitle, BListGroup, BListGroupItem, BBadge, BFormRadio, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'

export default ({
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BBadge,
    VuePerfectScrollbar,
    VueApexCharts,
    BFormRadio,
    flatPickr,
    BFormCheckbox,
    BButton,
  },
  data() {
    return {
      rangeDate: null,
      rangeDate1: null,
      rangeDate2: null,
      SelectedTemporally: [],
      SelectedStatus: [],
      selectedCustomSearch: true,
      items: ['Reps', 'Visit Types', 'Coverage Type', 'Sources', 'Site', 'Status', 'Interaction Type', 'Providers', 'Established/New', 'Services', 'Ages', 'InReach/OutReach'],
      listData: [
        { text: 'Cras justo odio' },
        { text: 'Dapibus ac facilisis in' },
        { text: 'Vestibulum at eros' },
        { text: 'Cras justo odio' },
        { text: 'Cras justo odio' },
        { text: 'Dapibus ac facilisis in' },
        { text: 'Vestibulum at eros' },
        { text: 'Cras justo odio' },
        { text: 'Cras justo odio' },
        { text: 'Dapibus ac facilisis in' },
        { text: 'Vestibulum at eros' },
        { text: 'Cras justo odio' },
        { text: 'Cras justo odio' },
        { text: 'Dapibus ac facilisis in' },
        { text: 'Vestibulum at eros' },
        { text: 'Cras justo odio' },
      ],
      series: [{
        name: ['Series'],
        data: [90, 40, 45, 50, 49, 60, 70, 91],
      }],
      revenueReport: {
        chartOptions: {
          theme: {
            palette: 'palette4',
          },
          chart: {
            type: 'bar',
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              columnWidth: '80%',
              distributed: true,
              endingShape: 'rounded',
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
          },
        },
      },
    }
  },
  methods: {
  },
})
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.anotherscrollvueheight{
  height: 70px !important;
}

.ps-container {
  height: 180px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
.flatpickr-calendar .flatpickr-day.inRange, .flatpickr-calendar .flatpickr-day.inRange:hover{
  color: white;
}

.backgroundGlass{
  background-image: url('https://media.istockphoto.com/photos/white-gray-gradient-abstract-backgroundgradient-background-picture-id854543626?k=20&m=854543626&s=170667a&w=0&h=wN3_U4kFB3UTAO6Gg-skMOhTOAOgZljXPePds5_FapM=');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
</style>
